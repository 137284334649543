<template>
  <section class="">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Edit Offer</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/offer-packages/offer-list" class="text-primary">
              Product Offers
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Edit
            Promotion Codes
          </li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-if="promo">
            <form @submit.prevent="updateOffer">
              <div class="mb-3 pt-3">

                <div class=" row">
                  <div class="col-md-6 ">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Title</label>
                          <input v-model="promo.title" type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Price</label>
                          <input v-model="promo.price" type="number" class="form-control" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Code</label>
                          <input v-model="promo.code" type="text" class="form-control" />
                        </div>
                      </div>





                      
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Image</label>
                          <b-button v-b-modal.modal-xl class="choose-image">Choose Image</b-button>

                          <chooseImage @imageId="setImageId"></chooseImage>
                          <vue-dropzone name="image" ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions"
                            :useCustomSlot="true" v-on:vdropzone-success="success" @vdropzone-sending="updateAvater">
                            <div class="dropzone-custom-content">
                              <div class="dropzone-custom-content">
                                <div v-if="bannerImg">
                                  <div class="image-size-dropzone">
                                    <img :src="`${imageApiURL}/${bannerImg.thumb_s}`" class="show-img img-fluid" />
                                  </div>
                                  <div class="subtitle pt-1 pb-0">
                                    <span> Change </span>
                                    <span style="color: #eb2027"> Browse</span>
                                  </div>
                                </div>
                                <div v-if="!bannerImg" class="pt-3">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                                    fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-upload" color="">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline points="17 8 12 3 7 8"></polyline>
                                    <line x1="12" y1="3" x2="12" y2="15"></line>
                                  </svg>
                                  <div class="subtitle pt-1 pb-5">
                                    <span> Drop File or </span>
                                    <span style="color: #eb2027"> Browse</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </vue-dropzone>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">

                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Total</label>
                          <input type="number" v-model="promo.total_off" class="form-control" />
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Qty</label>
                          <input type="number" v-model="promo.qty" class="form-control" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Percentage OFF</label>
                          <input type="number" v-model="promo.percentage_off" class="form-control" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Expiration Date</label>
                          <date-picker v-model="promo.expiration_data" format="YYYY-MM-DD" placeholder="Select Date"
                            valueType="format"></date-picker>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Tags</label>
                          <multiselect v-model="promo.tags" :multiple="true" :options="showTags" track-by="name"
                            label="name"></multiselect>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Description</label>
                          <textarea class="form-control" rows="4" v-model="promo.description"></textarea>
                        </div>
                      </div>
                      
                      <div class="col-12">
                        <div class="form-group mb-2 pb-1">
                          <label for="name" class="pr-2 pt-1">Active</label>
                          <label class="switch">
                            <input type="checkbox" v-model="promo.active" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-12">
                    <h4 class="products_offer">PRODUCTS IN THIS OFFER</h4>
                    <div class="form-group">
                      <span v-if="deleted == true">
                        <p>Selected Products:</p>
                        <ul class="card-selected_list">
                          <li for="name" class="offset-md-1" v-for="prod in this.promo.products">{{ prod.name }}
                          </li>
                          <br>
                          <span @click="deleteSelected">Select New Products</span>
                        </ul>
                      </span>
                    </div>
                  </div>
                  <Products :deleted="this.deleted" :inOffer="true" :inEdit="true" @toParent="handler"></Products> -->
                 
                  <div class="col-md-12 pt-2">
                    <div class="project-grid d-flex border mb-3">
                      <div class="wrapper pl-2 pb-2 pt-2 w-100">
                        <div class="wrapper d-flex align-items-center">
                          <div class="wrapper">
                            <h5 class="project-title pb-0 mb-0">Add Products in this Offer</h5>
                          </div>
                          <offerProduct @offerProductId="offerProductName"></offerProduct>

                          <b-button v-b-modal.modal-offerProduct
                            class="badge badge-success ml-auto text-capitalize float-right mr-2"
                            style="font-size: 11px">
                            Select Products</b-button>
                        </div>
                      </div>
                    </div>

                    <div class="project-grid border">
                      <h4 class="card-title p-2" block v-b-toggle.accordion-1 style="font-size: 14px !important">
                       Products in this Offer
                        <i class="mdi mdi mdi-plus menu-icon float-right"></i>
                      </h4>

                      <b-collapse visible id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <div class="table-responsive w-100 mb-0">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody v-for="offerProductId in offerProductId" :key="offerProductId.id">
                              <tr>
                                <td>
                                  {{ offerProductId.name }}
                                </td>
                                <td>
                                  {{
                                      offerProductId.price.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="col-12 p-3 text-center font-size-12" v-if="offerProductId == ''">
                            No Offer Products found this Product
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group float-right mt-3 mb-0">
                      <button class="btn btn btn-add btn-primary">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>

import { offerApi } from "../../api";
import Loader from "../../components/Loader";
import { required } from "vuelidate/lib/validators";
import chooseImage from "../../components/chooseImage";
import vue2Dropzone from "vue2-dropzone";
import offerProduct from "../../components/offerProduct";
import Multiselect from "vue-multiselect";
import axios from "axios";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import DatePicker from "vue2-datepicker";
import Products from "../catalog/product/products.vue";

export default {
  name: "edit-offer",
  components: {
    offerProduct, Loader, DatePicker, chooseImage, vueDropzone: vue2Dropzone, Multiselect, Products
  },
  data() {
    return {
      deleted: true,
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
      },
      offerProductId: [],
      showLoader: false,
      promo: {
        title: "",
        price: "",
        code: "",
        description: "",
        slug: "",
        active: true,
        total_off: "",
        qty: "",
        expiration_data: "",
        image_id: null,
        products: [],
        tags: null,
      },
      imageApiURL, bannerImg: "",
      showTags: [],
      tags_empty: [],
      sellected: [],
    };
  },
  validations: {
    promo: {
      title: { required },
      price: { required },
    }
  },
  mounted() {
    this.listById()
    this.listTags()
  },
  methods: {
    offerProductName(selectedId) {
      this.showLoader = true;

      this.offerProductId = selectedId;
      this.showLoader = false;
    },
    async listById() {
      this.showLoader = true;
      const { data } = await offerApi.listById(this.$route.params.id);
      this.promo = data.data.data[0];
      this.offerProductId = this.promo.products
      this.showLoader = false;
      if (this.promo.image_id) {
        this.avater();
      }
    },
    handler(value) {
      // Log Data From Child Component
      console.log(value)
      this.sellected = value
    },
    deleteSelected() {
      this.promo.products = [];
      this.deleted = false;
    },
    setImageId(selectedId) {
      this.showLoader = true;
      axios.get(`${mediaApi}/files/${selectedId}`).then((response) => {
        this.bannerImg = response.data.data[0];
        this.showLoader = false;
      });
    },
    async listTags() {
      this.showLoader = true;
      const { data } = await offerApi.listTags();
      this.showTags = data.data.data.data;
      this.showLoader = false;
    },
    updateAvater(file, xhr, formData) {
      let folder = "products";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", folder);
    },
    avater() {
      this.showLoader = true;
      axios.get(`${mediaApi}/files/${this.promo.image_id}`).then((response) => {
        this.bannerImg = response.data.data[0];
        this.showLoader = false;
      });
    },
    async updateOffer() {
      this.tags_empty = [];
      this.offerProductt_empty=[];
      this.submitted = true;

      for (var key in this.promo) {
        if (this.promo.hasOwnProperty(key)) {
          var val = this.promo[key];
          if (val === null) {
            delete this.promo[key];
          }
        }
      }

      if (this.promo.active === true) {
        this.promo.active = 1;
      } else {
        this.promo.active = 0;
      }

      if (this.promo.tags) {
        this.promo.tags.forEach((value) => {
          let tags = value.id;
          if (!this.tags_empty.includes(tags)) this.tags_empty.push(tags);
        });
        this.promo.tags = this.tags_empty;
      }
     

      if (this.offerProductId) {
        this.offerProductId.forEach((value) => {
          let item = value.id;
          if (!this.offerProductt_empty.includes(item))
            this.offerProductt_empty.push(item);
        });
        this.promo.products = this.offerProductt_empty;
        console.log(this.promo.products)
      }

      // if (this.promo.products.length > 0) {
      //   this.promo.products = this.promo.products.forEach(e => {
      //     return [e.id]
      //   })
      // } else if (this.sellected) {
      //   this.promo.products = this.sellected
      // }

      if (this.bannerImg) {
        this.promo.image_id = this.bannerImg.id
      }

      this.showLoader = true;
      const data = await offerApi.editOffer(this.$route.params.id, {
        ...this.promo,
      });
      this.showLoader = false;
      this.$router.push("/offer-packages/offer-list");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
    success(file, response) {
      this.promo.image_id = response.data.id;
      this.bannerImg = ""
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>